import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";

import logo from "../../images/default2.png";
import "./Navbar.css";

function Navbar() {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);
  const scrollToTop = () => {
    window.scrollTo(0, 0);
    setClick(false);
  };

  return (
    <>
      <div className="call-banner">
        <i className="fa fa-phone"></i>
        <a href="tel:+19108778588">+1 910 877 8588</a>
      </div>
      <nav className="navbar">
        <div className="navbar-container">
          <Link to="/" className="navbar-logo" onClick={scrollToTop}>
            <img src={logo} alt="logo"></img>
          </Link>
          <div className="menu-icon" onClick={handleClick}>
            <i className={click ? "fa fa-times" : "fa fa-bars"} />
          </div>
          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li className="nav-item">
              <a
                href="tel:+19108778588"
                className="nav-links"
                onClick={scrollToTop}
              >
                <span>Call us now!</span>
              </a>
              {/* <Link to="/" className="nav-links" onClick={scrollToTop}>
                <span>
                  <a href="tel:+40769319920">Call us now!</a>
                </span>
              </Link> */}
            </li>
            <li className="nav-item">
              <NavLink to="/" className="nav-links" onClick={scrollToTop}>
                <span>Home</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/about" className="nav-links" onClick={scrollToTop}>
                <span>Why Us?</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/paymentPlans"
                className="nav-links"
                onClick={scrollToTop}
              >
                <span>Payment plans</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/reviews"
                className="nav-links"
                onClick={scrollToTop}
              >
                <span>Reviews</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/FAQ" className="nav-links" onClick={scrollToTop}>
                <span>FAQ</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/contact"
                className="nav-links"
                onClick={scrollToTop}
              >
                <span>Contact</span>
              </NavLink>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
