import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./component/Navbar/Navbar";
import Home from "./component/Home/Home";
import Footer from "./component/Footer/Footer";
import WhyUs from "./component/WhyUs/WhyUs";
import PaymentPlans from "./component/PaymentPlans/PaymentPlans";
import Reviews from "./component/Reviews/Reviews";
import FAQ from "./component/FAQ/FAQ";
import Contact from "./component/Contact/Contact";

function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" exact element={<Home />} />
        <Route path="/about" exact element={<WhyUs />} />
        <Route path="/paymentPlans" exact element={<PaymentPlans />} />
        <Route path="/reviews" exact element={<Reviews />} />
        <Route path="/faq" exact element={<FAQ />} />
        <Route path="/contact" exact element={<Contact />} />
      </Routes>
      {/* <Home />
      <WhyUs />
      <HomeReviews />
      <HomeOffer />
      <ContactForm /> */}
      <Footer />
    </Router>
  );
}

export default App;
