import React from "react";
import "./FAQ.css";

const FAQ = () => {
  return (
    <>
      {/**************************************
       *                                     *
       *            Page Band                *
       *                                     *
       *                                     *
       ***************************************/}
      <div className="cpage-1container">
        <div className="cpage-2container">
          <div className="cpage-3container"></div>
          <div className="cpage-4container">
            <h1>FAQ</h1>
          </div>
        </div>
      </div>
      {/**************************************
       *                                     *
       *               Main                  *
       *                                     *
       *                                     *
       ***************************************/}
      <div className="faq-main-container">
        <div className="faq-second-container">
          {/**************************************
           *                                     *
           *             FAQ ROW 1               *
           *                                     *
           *                                     *
           ***************************************/}
          <div className="faq-row">
            <div className="faq-question">
              <div className="Q">Q</div>
              <div className="faq-q"> Can I refuse a load?</div>
            </div>
            <div className="faq-answer">
              <div className="A">A</div>
              <div className="faq-a">
                Of course you can, you are in control of your dispatching needs
                at any time and it’s crucial for your dispatcher to understand
                your preferences.
              </div>
            </div>
          </div>
          <div className="faq-line"></div>
          {/**************************************
           *                                     *
           *             FAQ ROW 2               *
           *                                     *
           *                                     *
           ***************************************/}
          <div className="faq-row">
            <div className="faq-question">
              <div className="Q">Q</div>
              <div className="faq-q">
                What types of equiptment do we work with?
              </div>
            </div>
            <div className="faq-answer">
              <div className="A">A</div>
              <div className="faq-a">
                48 or 53 ft Dry Vans, Reefers, Flatbeds, Stepdecks and Power
                Only (PO)
              </div>
            </div>
          </div>
          <div className="faq-line"></div>

          {/**************************************
           *                                     *
           *             FAQ ROW 3               *
           *                                     *
           *                                     *
           ***************************************/}
          <div className="faq-row">
            <div className="faq-question">
              <div className="Q">Q</div>
              <div className="faq-q"> What is a factoring company?</div>
            </div>
            <div className="faq-answer">
              <div className="A">A</div>
              <div className="faq-a">
                A factoring company is an agency which purchases your invoices,
                pays you ACH or Wire and gets payed by the brokers after 30-90
                days for a small fee (3% at the MAX).
              </div>
            </div>
          </div>
          <div className="faq-line"></div>

          {/**************************************
           *                                     *
           *             FAQ ROW 4               *
           *                                     *
           *                                     *
           ***************************************/}
          <div className="faq-row">
            <div className="faq-question">
              <div className="Q">Q</div>
              <div className="faq-q"> Is a factoring company a good ideea?</div>
            </div>
            <div className="faq-answer">
              <div className="A">A</div>
              <div className="faq-a">
                We strongly support the ideea of a factoring company so you can
                keep up the cash flow without having the trouble of invoices,
                paperwork and waiting time.
              </div>
            </div>
          </div>
          <div className="faq-line"></div>

          {/**************************************
           *                                     *
           *             FAQ ROW 5               *
           *                                     *
           *                                     *
           ***************************************/}
          <div className="faq-row">
            <div className="faq-question">
              <div className="Q">Q</div>
              <div className="faq-q"> Can I pick my routes?</div>
            </div>
            <div className="faq-answer">
              <div className="A">A</div>
              <div className="faq-a">
                Point your finger at the map and let us take you there!
              </div>
            </div>
          </div>
          <div className="faq-line"></div>

          {/**************************************
           *                                     *
           *             FAQ ROW 6               *
           *                                     *
           *                                     *
           ***************************************/}
          <div className="faq-row">
            <div className="faq-question">
              <div className="Q">Q</div>
              <div className="faq-q"> How can I pay for your services?</div>
            </div>
            <div className="faq-answer">
              <div className="A">A</div>
              <div className="faq-a">
                Visa, MasterCard, Maestro, Bitcoin, Wire – Transfer
              </div>
            </div>
          </div>
          <div className="faq-line"></div>

          {/**************************************
           *                                     *
           *             FAQ ROW 7               *
           *                                     *
           *                                     *
           ***************************************/}
          <div className="faq-row">
            <div className="faq-question">
              <div className="Q">Q</div>
              <div className="faq-q"> How do I sign up?</div>
            </div>
            <div className="faq-answer">
              <div className="A">A</div>
              <div className="faq-a">
                Complete a non-bonding contract and some paperwork exchange. It
                takes no more than 10 minutes, easy and simple.
              </div>
            </div>
          </div>
          <div className="faq-line"></div>

          {/**************************************
           *                                     *
           *             FAQ ROW 8               *
           *                                     *
           ***************************************/}
          <div className="faq-row">
            <div className="faq-question">
              <div className="Q">Q</div>
              <div className="faq-q"> Is the contract a long-term one ?</div>
            </div>
            <div className="faq-answer">
              <div className="A">A</div>
              <div className="faq-a">
                Nope, you can be in and out as you wish, but our clients prefer
                to stay.
              </div>
            </div>
          </div>
          <div className="faq-line"></div>
        </div>
      </div>
    </>
  );
};

export default FAQ;
