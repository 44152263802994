import { React, useState } from "react";
import { Link } from "react-router-dom";
import "./Footer.css";
import logo from "../../images/default2.png";
import Payments from "../../images/plati-online3.png";

const Footer = () => {
  const [value, onChange] = useState(new Date());
  const [click, setClick] = useState(false);

  const scrollToTop = () => {
    window.scrollTo(0, 0);
    setClick(false);
  };
  return (
    <div className="footer-main-container">
      <div className="footer-second-container">
        <footer id="footer">
          <div className="footer-col1">
            {/* <h4>DOWNLOAD OUR APP</h4>
        <p>Download App for Android and IOS mobile phone</p> */}
            <Link to="/" className="footer-logo" onClick={scrollToTop}>
              <img src={logo} alt="logo" />
            </Link>
            {/* <a href="/"></a> */}
            {/* <Link to="/" className="footer-payments" onClick={scrollToTop}>
              <img src={visaImg} alt="logo2" />
              <img src={masterCardImg} alt="logo2" />
              <img src={Payments} alt="logo2" />
            </Link> */}
          </div>

          {/* <div className="footer-col2">
            <h4>Usefull Links</h4>
            <p>Porter Freight Factoring</p>
            <p>Marquee Insurance Group</p>
            <p>Emodal</p>
            <p>Owner Operators Association</p>
            <p>Diesel Prices</p>
            <p>Credit Check</p>
            <p>OTR CAPITAL</p>

          </div> */}
          {/* <div className="footer-col2">
            <Link to="/" className="footer-logo" onClick={scrollToTop}>
              <img src={logo} alt="logo" />
            </Link>
          </div> */}

          {/* <div className="footer-col3">
            <h4>Calendar</h4>
            <Calendar className="calendar" onChange={onChange} value={value} />
          </div> */}

          <div className="footer-col4">
            <h4>Call Us now</h4>
            <a href="tel:19108778588">+1 910 877 8588</a>
            <div className="social-media">
              <h4>Social Media</h4>

              <a href="https://www.facebook.com/" role="button">
                <i className="fa fa-facebook-f fa-lg"></i>
              </a>

              <a href="https://twitter.com/" role="button">
                <i className="fa fa-twitter fa-lg"></i>
              </a>

              <a href="https://www.instagram.com/" role="button">
                <i className="fa fa-instagram fa-lg"></i>
              </a>
            </div>

            {/* <a href="https://www.instagram.com/">Instagram</a>
            <a href="https://www.youtube.com/">Youtube</a>
            <a href="https://www.facebook.com/">Facebook</a> */}
          </div>
          <div className="footer-col2">
            <h4>Usefull Links</h4>
            <p>
              <a href="/EasyDispatch-Terms.pdf" target="_blank">
                Terms
              </a>
            </p>
            <Link to="/" className="footer-payments" onClick={scrollToTop}>
              {/* <img src={logo2} alt="logo2" /> */}
              {/* <img src={visaImg} alt="logo2" />
              <img src={masterCardImg} alt="logo2" /> */}
              <img src={Payments} alt="logo2" />
            </Link>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default Footer;
