import React from "react";
import "./HomeWhyUs.css";
// import whyImg from "../../images/whyimg.jpg";

const WhyUs = () => {
  return (
    <div className="first-container">
      <div className="main-container">
        <div className="why-container">
          <div className="title-container">
            <span className="before-text">
              <div className="before-line-div"></div>
            </span>
            <h1> Why Us?</h1>
            <span className="after-text">
              <div className="before-line-div"></div>
            </span>
          </div>

          <div className="second-container">
            <div className="why-description">
              <p>
                We are a team with experience in the field of dispatching. We
                offer the best quality services 24/7, both for the Operational
                Owner and for companies with car fleets of all sizes. Rate
                negotiation is one of the most important qualities we have.
              </p>
              <p>
                Our mission is to take advantage of every day to increase the
                profit to the maximum potential and the customer to be
                satisfied. For us, the connection between the driver and the
                dispatcher is important, that's why we have highly patient and
                understanding dispatchers.
              </p>
              <p>
                We are at your disposal, <a href="tel:+19108778588">Call us!</a>
              </p>
            </div>
            <div className="why-image-container">
              <div className="why-image">
                {/* <img src={whyImg} alt=""></img> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyUs;
