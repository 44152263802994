import { React, useState } from "react";
import { Link } from "react-router-dom";
import "./PaymentPlans.css";
import CheckIcon from "@mui/icons-material/Check";

const PaymentPlans = () => {
  const [click, setClick] = useState(false);

  const scrollToTop = () => {
    window.scrollTo(0, 0);
    setClick(false);
  };

  return (
    <>
      {/**************************************
       *                                     *
       *          Page Band                  *
       *                                     *
       *                                     *
       ***************************************/}
      <div className="cpage-1container">
        <div className="cpage-2container">
          <div className="cpage-3container"></div>
          <div className="cpage-4container">
            <h1>PaymentPlans</h1>
          </div>
        </div>
      </div>
      <div className="pPlan-main-container">
        <div className="pPlan-second-container">
          <div className="row has-price">
            {/**************************************
             *                                     *
             *          First offer                *
             *                                     *
             *                                     *
             ***************************************/}
            <div
              className="wpv-grid grid-1-3  wpv-first-level first unextended"
              id="wpv-column-cc241e7477d102c2ba8a0b097c1b5c08"
            >
              <div className="price-outer-wrapper">
                <div className="price-wrapper ">
                  {/* <h3 className="price-title">Power Only Dispatch</h3> */}
                  <div className="price">
                    <div className="value-box">
                      <div className="value-box-content">
                        <div>
                          <h3>Power Only Dispatch</h3>
                          <h2>6% OFF </h2>
                        </div>
                      </div>
                    </div>

                    <div className="content-box">
                      <div className="value-box-bottom-line"></div>
                      <ul>
                        <li>
                          <CheckIcon />
                          <div>Booking Loads</div>
                        </li>
                        <li>
                          <CheckIcon />
                          <div>
                            Filling Setup packets / Contracts with
                            brokers/shippers
                          </div>
                        </li>
                        <li>
                          <CheckIcon />
                          <div>Control over dispatch decisions</div>
                        </li>
                        <li>
                          <CheckIcon />
                          <div>Insurance-related task (Updated COI’s)</div>
                        </li>
                        <li>
                          <CheckIcon />
                          <div>Generate Invoices for brokers/shippers</div>
                        </li>
                        <li>
                          <CheckIcon />
                          <div>Credit Checks (Brokers/Shippers)</div>
                        </li>
                        <li>
                          <CheckIcon />
                          <div>
                            Sending faxes/emails/texts on your behalf (if
                            needed)
                          </div>
                        </li>
                        <li>
                          <CheckIcon />
                          <div>Dispatcher support(8-5 Eastern Time)</div>
                        </li>
                        <li>
                          <CheckIcon />
                          <div>Detention / Layover / TONU support</div>
                        </li>
                        <li>
                          <CheckIcon />
                          <div>No dead times</div>
                        </li>
                        <li>
                          <CheckIcon />
                          <div>Mixing LTL for better rates (if needed)</div>
                        </li>
                        <li>
                          <CheckIcon />
                          <div>Payload Control (Brokers pay you, not us)</div>
                        </li>
                        <li>
                          <CheckIcon />
                          <div> Insurance Quotes</div>
                        </li>
                        <li>
                          <CheckIcon />
                          <div>
                            Follow up on any payments or detentions/collections.
                          </div>
                        </li>
                        <li>
                          <CheckIcon />
                          <div>24/7 option available for extra 1%</div>
                        </li>
                      </ul>
                    </div>
                    <div className="meta-box">
                      {/* <a className="pPlan-btn" href="/"> */}
                      <Link
                        to="/contact"
                        className="pPlan-btn"
                        onClick={scrollToTop}
                      >
                        Order Now!
                      </Link>
                      {/* </a> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/**************************************
             *                                     *
             *          Second offer               *
             *                                     *
             *                                     *
             ***************************************/}
            <div
              className="wpv-grid grid-1-3  wpv-first-level first unextended"
              id="wpv-column-cc241e7477d102c2ba8a0b097c1b5c08"
            >
              <div className="price-outer-wrapper2">
                <div className="price-wrapper">
                  <div className="price">
                    <div className="value-box2">
                      <div className="value-box2-content">
                        <div>
                          <h3>Semi-Truck Dispatch</h3>
                          <h2>5% OFF </h2>
                        </div>
                      </div>
                    </div>

                    <div className="content-box2">
                      <div className="value-box-bottom-line"></div>
                      <ul>
                        <li>
                          <CheckIcon />
                          <div>Booking Loads</div>
                        </li>
                        <li>
                          <CheckIcon />
                          <div>
                            Filling Setup packets / Contracts with
                            brokers/shippers
                          </div>
                        </li>
                        <li>
                          <CheckIcon />
                          <div>Control over dispatch decisions</div>
                        </li>
                        <li>
                          <CheckIcon />
                          <div>Insurance-related task (Updated COI’s)</div>
                        </li>
                        <li>
                          <CheckIcon />
                          <div>Generate Invoices for brokers/shippers</div>
                        </li>
                        <li>
                          <CheckIcon />
                          <div>Credit Checks (Brokers/Shippers)</div>
                        </li>
                        <li>
                          <CheckIcon />
                          <div>
                            Sending faxes/emails/texts on your behalf (if
                            needed)
                          </div>
                        </li>
                        <li>
                          <CheckIcon />
                          <div>Dispatcher support(8-5 Eastern Time)</div>
                        </li>
                        <li>
                          <CheckIcon />
                          <div>Detention / Layover / TONU support</div>
                        </li>
                        <li>
                          <CheckIcon />
                          <div>No dead times</div>
                        </li>
                        <li>
                          <CheckIcon />
                          <div>Mixing LTL for better rates (if needed)</div>
                        </li>
                        <li>
                          <CheckIcon />
                          <div>Payload Control (Brokers pay you, not us)</div>
                        </li>
                        <li>
                          <CheckIcon />
                          <div> Insurance Quotes</div>
                        </li>
                        <li>
                          <CheckIcon />
                          <div>
                            Follow up on any payments or detentions/collections.
                          </div>
                        </li>
                        <li>
                          <CheckIcon />
                          <div>24/7 option available for extra 1%</div>
                        </li>
                      </ul>
                    </div>
                    <div className="meta-box2">
                      <Link
                        to="/contact"
                        className="pPlan-btn2"
                        onClick={scrollToTop}
                      >
                        Order Now!
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/**************************************
             *                                     *
             *          Third offer                *
             *                                     *
             *                                     *
             ***************************************/}
            <div
              className="wpv-grid grid-1-3  wpv-first-level first unextended"
              id="wpv-column-cc241e7477d102c2ba8a0b097c1b5c08"
            >
              <div className="price-outer-wrapper">
                <div className="price-wrapper ">
                  <div className="price">
                    <div className="value-box">
                      <div className="value-box-content">
                        <div>
                          <h3>CDL Hot-Shot Dispatch</h3>
                          <h2>7% OFF </h2>
                        </div>
                      </div>
                    </div>

                    <div className="content-box">
                      <div className="value-box-bottom-line"></div>

                      <ul>
                        <li>
                          <CheckIcon />
                          <div>Booking Loads</div>
                        </li>
                        <li>
                          <CheckIcon />
                          <div>
                            Filling Setup packets / Contracts with
                            brokers/shippers
                          </div>
                        </li>
                        <li>
                          <CheckIcon />
                          <div>Control over dispatch decisions</div>
                        </li>
                        <li>
                          <CheckIcon />
                          <div>Insurance-related task (Updated COI’s)</div>
                        </li>
                        <li>
                          <CheckIcon />
                          <div> Generate Invoices for brokers/shippers</div>
                        </li>
                        <li>
                          <CheckIcon />
                          <div> Credit Checks (Brokers/Shippers)</div>
                        </li>
                        <li>
                          <CheckIcon />
                          <div>
                            {" "}
                            Sending faxes/emails/texts on your behalf (if
                            needed)
                          </div>
                        </li>
                        <li>
                          <CheckIcon />
                          <div>Dispatcher support(8-5 Eastern Time)</div>
                        </li>
                        <li>
                          <CheckIcon />
                          <div> Detention / Layover / TONU support</div>
                        </li>
                        <li>
                          <CheckIcon />
                          <div> No dead times</div>
                        </li>
                        <li>
                          <CheckIcon />
                          <div>Mixing LTL for better rates (if needed)</div>
                        </li>
                        <li>
                          <CheckIcon />
                          <div>Payload Control (Brokers pay you, not us)</div>
                        </li>
                        <li>
                          <CheckIcon />
                          <div> Insurance Quotes</div>
                        </li>
                        <li>
                          <CheckIcon />
                          <div>
                            Follow up on any payments or detentions/collections.
                          </div>
                        </li>
                        <li>
                          <CheckIcon />
                          <div> 24/7 option available for extra 1%</div>
                        </li>
                      </ul>
                    </div>
                    <div className="meta-box">
                      <Link
                        to="/contact"
                        className="pPlan-btn"
                        onClick={scrollToTop}
                      >
                        Order Now!
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentPlans;
