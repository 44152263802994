import React from "react";
import "./HeroSection.css";
import heroImg from "../../images/logo.jpg";

const HeroSection = () => {
  return (
    <div className="comp-container">
      <div className="slider-container">
        <img src={heroImg} alt=""></img>
      </div>
    </div>
  );
};

export default HeroSection;
