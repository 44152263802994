import React from "react";
import "./WhyUs.css";
import whyUsImg from "../../images/logo3.jpg";

const WhyUs = () => {
  return (
    <>
      <div className="cpage-1container">
        <div className="cpage-2container">
          <div className="cpage-3container"></div>
          <div className="cpage-4container">
            <h1>Why Us</h1>
          </div>
        </div>
      </div>
      <div className="whyus-container">
        <div className="whyus-main-container">
          <div className="whyus-container">
            <div className="whyus-second-container">
              <div className="whyus-description">
                <p>
                  Our main goal is to make sure that any company, regardless of
                  its size, reaches its potential in its entirety. Thus, we work
                  with any mode of transportation in order to accommodate your
                  needs, from Truck Load (TL) and Less than truckload (LTL), to
                  the likes of Reefer (R, Flatbed (F), and even Step-Deck(SD)
                  and HotShot(HS). Our first priority is to make sure that you
                  feel like we belong in your team by establishing a strong
                  relationship between driver and dispatcher, thus, creating a
                  steady cash flow for the company. In order to carefully
                  understand your equipment, schedule, and your preferences in
                  terms of truckloads, we work with all the available truckloads
                  and gain insight into private load lists before they reach the
                  market. In order to be the best at what we do, we also keep a
                  constant eye on the calendar and different regions of the US.
                  This is allowing us to maximize the potential of a certain
                  lane when certain seasons, such as watermelon, olive, and
                  vegetable season arrive. Get rid of excessive downtime and no
                  communication with your dispatcher by contacting us today!
                </p>
              </div>
              <div className="whyus-image-container">
                <div className="whyus-image">
                  <img src={whyUsImg} alt=""></img>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WhyUs;
