import React from "react";
import "./HomeReviews.css";

const HomeReviews = () => {
  return (
    <div className="rev-main-container">
      <div className="rev-second-container">
        <div className="col-1">
          <div className="rev-title">
            <i className="fa fa-star"></i>
            <i className="fa fa-star"></i>
            <i className="fa fa-star"></i>
            <i className="fa fa-star"></i>
            <i className="fa fa-star"></i>
            <div className="rev-title-text"> — Timothy Davis</div>
          </div>
          <div className="rev-pause"></div>
          <div className="rev-msg">
            I've start to work with John at EasyDispatch from my first day of
            authority, he keeps me busy all the time and he is a really nice and
            transparent guy.
          </div>
          <div className="rev-dot">
            <i className="fa fa-circle"></i>
          </div>
        </div>
        {/* <div className="col-2">
          <div className="rev-title">
            <i className="fa fa-star"></i>
            <i className="fa fa-star"></i>
            <i className="fa fa-star"></i>
            <i className="fa fa-star"></i>
            <i className="fa fa-star"></i>
            <div className="rev-title-text"> — Lorem Ipsum</div>
          </div>
          <div className="rev-msg">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras vitae
            magna vulputate, hendrerit mi congue, congue nulla. Proin bibendum
            felis in risus tempus pulvinar. Morbi sed velit nec justo pharetra
            elementum sed quis lectus. Pellentesque rhoncus est et magna congue.
          </div>
          <div className="rev-dot">
            <i className="fa fa-circle"></i>
          </div>
        </div>
        <div className="col-3">
          <div className="rev-title">
            <i className="fa fa-star"></i>
            <i className="fa fa-star"></i>
            <i className="fa fa-star"></i>
            <i className="fa fa-star"></i>
            <i className="fa fa-star"></i>
            <div className="rev-title-text"> — Lorem Ipsum</div>
          </div>
          <div className="rev-msg">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras vitae
            magna vulputate, hendrerit mi congue, congue nulla. Proin bibendum
            felis in risus tempus pulvinar. Morbi sed velit nec justo pharetra
            elementum sed quis lectus. Pellentesque rhoncus est et magna congue.
          </div>
          <div className="rev-dot">
            <i className="fa fa-circle"></i>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default HomeReviews;
