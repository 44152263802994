import React from "react";
import HeroSection from "../HeroSection/HeroSection";
import HomeWhyUs from "../HomeWhyUs/HomeWhyUs";
import HomeReviews from "../HomeReviews/HomeReviews";
import HomeOffer from "../HomeOffer/HomeOffer";
import ContactForm from "../ContactForm/ContactForm";

const Home = () => {
  return (
    <>
      <HeroSection />
      <HomeWhyUs />
      <HomeReviews />
      <HomeOffer />
      <ContactForm />
    </>
  );
};

export default Home;
