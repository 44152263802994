import React, { useState } from "react";
import Swal from "sweetalert2";
import "./Contact.css";

const Contact = () => {
  const [status, setStatus] = useState("Submit");
  const [CompanyNameValue, setCompanyNameValue] = useState("");
  const [EmailValue, setEmailValue] = useState("");
  const [NumberValue, setNumberValue] = useState("");
  const [MessageValue, setMessageValue] = useState("");
  const [DryVan, setDryVan] = useState(true);
  const [StepDeck, setStepDeck] = useState(true);
  const [DoubleDrop, setDoubleDrop] = useState(true);
  const [Reefer, setReefer] = useState(true);
  const [HotShot, setHotShot] = useState(true);
  const [FlatBed, setFlatBed] = useState(true);
  const [checkboxes, setCheckboxes] = useState([]);
  var ArrOpt;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus("Sending...");
    let destination = "Easydispatch33@gmail.com";
    //   let destination = "tr.marius@yahoo.com";
    const { name, email, mobile, message } = e.target.elements;
    let details = {
      name: name.value,
      email: email.value,
      mobile: mobile.value,
      message: message.value,
      to: destination,
      options: checkboxes,
    };

    let response = await fetch(
      "https://mail-server-dispatch.herokuapp.com/contact",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify(details),
      }
    );
    setStatus("Submit");
    let result = await response.json();
    //   alert(result.status);

    if ((result.status = "Message Sent")) {
      Swal.fire({
        title: "Message sent!",
        text: "We will contact you as soon as possible.",
        icon: "success",
        timer: 5000,
      });

      setCompanyNameValue("");
      setEmailValue("");
      setNumberValue("");
      setMessageValue("");
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "It was a problem sending your message.!",
        footer:
          '<div style="text-align: center">You can try to send the email from your email client to the address: office@reynainvest.com</div>',
        timer: 5000,
      });
    }
  };

  return (
    <>
      {/**************************************
       *                                     *
       *          Page Band                  *
       *                                     *
       ***************************************/}
      <div className="cpage-1container">
        <div className="cpage-2container">
          <div className="cpage-3container"></div>
          <div className="cpage-4container">
            <h1>Contact</h1>
          </div>
        </div>
      </div>

      {/**************************************
       *                                     *
       *               Main                  *
       *                                     *
       ***************************************/}
      <div className="contact-main-container">
        <div className="contact-second-container">
          <div className="contact-col-1">
            <h2>GET IN TOUCH!</h2>
            <p className="contact-desc">
              Biolescu S.R.L. Bulevardul 1 Decembrie 1918 Nr. 43{" "}
            </p>
            <p>CUI/VAT RO42762487 / REGISTRATION NUMBER J40/7856/2020 </p>
            <p>MONDAY TO SUNDAY OPEN FROM 05:00 AM TO 22:00 PM </p>{" "}
            <p>Email : john@easydispatch33.com(Response tine 24H).</p>
            <div className="contact-call-us">
              Call us now at <a href="tel:19108778588">+1 910 877 8588</a>
            </div>
            <div className="contact-photo-container">
              <div className="contact-photo"></div>
            </div>
          </div>
          <div className="contact-col-2">
            <form onSubmit={handleSubmit}>
              <div className="contactform-Form">
                <div className="inputBox w50">
                  <label>Company Name</label>
                  <input
                    type="text"
                    id="name"
                    name=""
                    onChange={(event) =>
                      setCompanyNameValue(event.target.value)
                    }
                    value={CompanyNameValue}
                    required
                  />
                </div>
                <div className="inputBox w50">
                  <label>Phone Number</label>
                  <input
                    type="text"
                    id="mobile"
                    name=""
                    onChange={(event) => setNumberValue(event.target.value)}
                    value={NumberValue}
                    required
                  />
                </div>
                <div className="inputBox w50">
                  <label>Your Email</label>
                  <input
                    type="email"
                    id="email"
                    name=""
                    onChange={(event) => setEmailValue(event.target.value)}
                    value={EmailValue}
                    required
                  />
                </div>

                <div className="inputBox w50">
                  <label>Your Message</label>
                  <textarea
                    name=""
                    id="message"
                    onChange={(event) => setMessageValue(event.target.value)}
                    value={MessageValue}
                    required
                  ></textarea>
                </div>

                <div className="checkbox-area">
                  <input
                    type="checkbox"
                    name="dry-van"
                    id="dry-van"
                    onClick={() => {
                      setDryVan(!DryVan);
                      console.log(DryVan);
                      ArrOpt = checkboxes;
                      if (DryVan) {
                        ArrOpt.push(" Dry-Van");
                        console.log(ArrOpt);
                      } else {
                        ArrOpt = ArrOpt.filter((e) => e !== " Dry-Van");
                        console.log(ArrOpt);
                      }
                      setCheckboxes(ArrOpt);
                    }}
                  ></input>
                  <label>Dry-Van</label>

                  <input
                    type="checkbox"
                    name="step-deck"
                    id="step-deck"
                    defaultChecked={false}
                    // value="Step-Deck"

                    onClick={() => {
                      setStepDeck(!StepDeck);
                      console.log(StepDeck);
                      ArrOpt = checkboxes;
                      if (StepDeck) {
                        ArrOpt.push(" Step-Deck");
                        console.log(ArrOpt);
                      } else {
                        ArrOpt = ArrOpt.filter((e) => e !== " Step-Deck");
                        console.log(ArrOpt);
                      }
                      setCheckboxes(ArrOpt);
                    }}
                  ></input>
                  <label>Step-Deck</label>

                  <input
                    type="checkbox"
                    name="double-drop"
                    id="double-drop"
                    value={DoubleDrop}
                    onClick={() => {
                      setDoubleDrop(!DoubleDrop);
                      console.log(DoubleDrop);
                      ArrOpt = checkboxes;
                      if (DoubleDrop) {
                        ArrOpt.push(" Double-Drop");
                        console.log(ArrOpt);
                      } else {
                        ArrOpt = ArrOpt.filter((e) => e !== " Double-Drop");
                        console.log(ArrOpt);
                      }
                      setCheckboxes(ArrOpt);
                    }}
                  ></input>
                  <label>Double-Drop</label>

                  <input
                    type="checkbox"
                    name="reefer"
                    id="reefer"
                    value={Reefer}
                    onClick={() => {
                      setReefer(!Reefer);
                      console.log(Reefer);
                      ArrOpt = checkboxes;
                      if (Reefer) {
                        ArrOpt.push(" Reefer");
                        console.log(ArrOpt);
                      } else {
                        ArrOpt = ArrOpt.filter((e) => e !== " Reefer");
                        console.log(ArrOpt);
                      }
                      setCheckboxes(ArrOpt);
                    }}
                  ></input>
                  <label>Reefer</label>

                  <input
                    type="checkbox"
                    name="hot-shot"
                    id="hot-shot"
                    value={HotShot}
                    onClick={() => {
                      setHotShot(!HotShot);
                      console.log(HotShot);
                      ArrOpt = checkboxes;
                      if (HotShot) {
                        ArrOpt.push(" Hot-Shot");
                        console.log(ArrOpt);
                      } else {
                        ArrOpt = ArrOpt.filter((e) => e !== " Hot-Shot");
                        console.log(ArrOpt);
                      }
                      setCheckboxes(ArrOpt);
                    }}
                  ></input>
                  <label>Hot-Shot</label>

                  <input
                    type="checkbox"
                    name="flat-bed"
                    id="flat-bed"
                    value={FlatBed}
                    onClick={() => {
                      setFlatBed(!FlatBed);
                      console.log(HotShot);
                      ArrOpt = checkboxes;
                      if (FlatBed) {
                        ArrOpt.push(" Flat-Bed");
                        console.log(ArrOpt);
                      } else {
                        ArrOpt = ArrOpt.filter((e) => e !== " Flat-Bed");
                        console.log(ArrOpt);
                      }
                      setCheckboxes(ArrOpt);
                    }}
                  ></input>
                  <label>Flat-Bed</label>
                </div>

                <div className="inputBox w50">
                  <button type="submit">{status}</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
