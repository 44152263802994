import { React, useState } from "react";
import { Link } from "react-router-dom";
import "./HomeOffer.css";

const HomeOffer = () => {
  const [click, setClick] = useState(false);

  const scrollToTop = () => {
    window.scrollTo(0, 0);
    setClick(false);
  };

  return (
    <div className="hoff-main-container">
      <div className="hoff-second-container">
        <div className="hoff-rectangle">
          <div className="hoff-value-box">
            <div className="hoff-h2">First month!</div>

            <div className="hoff-h1">10% off</div>
            {/* <div className="hoff-month"></div> */}
          </div>
        </div>
        <div className="hoff-box"></div>
        <div className="hoff-button">
          {/* <a className="hoff-btn" href="/"> */}
          <Link to="/contact" className="hoff-btn" onClick={scrollToTop}>
            Get yours now!
          </Link>
          {/* </a> */}
        </div>
      </div>
    </div>
  );
};

export default HomeOffer;
