import React from "react";
import "./Reviews.css";

const Reviews = () => {
  return (
    <>
      {/**************************************
       *                                     *
       *          Page Band                  *
       *                                     *
       *                                     *
       ***************************************/}
      <div className="cpage-1container">
        <div className="cpage-2container">
          <div className="cpage-3container"></div>
          <div className="cpage-4container">
            <h1>Reviews</h1>
          </div>
        </div>
      </div>
      <div className="reviews-main-container">
        <div className="reviews-second-container">
          <div className="reviews-row-1">
            <div className="reviews-col-1">
              <div className="reviews-rev-title">
                <i className="fa fa-star"></i>
                <i className="fa fa-star"></i>
                <i className="fa fa-star"></i>
                <i className="fa fa-star"></i>
                <i className="fa fa-star"></i>
                <div className="rev-title-text"> — Timothy Davis</div>
              </div>
              <div className="rev-pause"></div>
              <div className="reviews-rev-msg">
                I've start to work with John at EasyDispatch from my first day
                of authority, he keeps me busy all the time and he is a really
                nice and transparent guy.
              </div>
              <div className="reviews-rev-dot">
                <i className="fa fa-circle"></i>
              </div>
            </div>
            {/* <div className="reviews-col-2">
              <div className="reviews-rev-title">
                <i className="fa fa-star"></i>
                <i className="fa fa-star"></i>
                <i className="fa fa-star"></i>
                <i className="fa fa-star"></i>
                <i className="fa fa-star"></i>
                <div className="reviews-rev-title-text"> — Lorem Ipsum</div>
              </div>
              <div className="reviews-rev-msg">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras
                vitae magna vulputate, hendrerit mi congue, congue nulla. Proin
                bibendum felis in risus tempus pulvinar. Morbi sed velit nec
                justo pharetra elementum sed quis lectus. Pellentesque rhoncus
                est et magna congue.
              </div>
              <div className="reviews-rev-dot">
                <i className="fa fa-circle"></i>
              </div>
            </div>
            <div className="reviews-col-3">
              <div className="reviews-rev-title">
                <i className="fa fa-star"></i>
                <i className="fa fa-star"></i>
                <i className="fa fa-star"></i>
                <i className="fa fa-star"></i>
                <i className="fa fa-star"></i>
                <div className="reviews-rev-title-text"> — Lorem Ipsum</div>
              </div>
              <div className="reviews-rev-msg">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras
                vitae magna vulputate, hendrerit mi congue, congue nulla. Proin
                bibendum felis in risus tempus pulvinar. Morbi sed velit nec
                justo pharetra elementum sed quis lectus. Pellentesque rhoncus
                est et magna congue.
              </div>
              <div className="reviews-rev-dot">
                <i className="fa fa-circle"></i>
              </div>
            </div>
            <div className="reviews-col-4">
              <div className="reviews-rev-title">
                <i className="fa fa-star"></i>
                <i className="fa fa-star"></i>
                <i className="fa fa-star"></i>
                <i className="fa fa-star"></i>
                <i className="fa fa-star"></i>
                <div className="reviews-rev-title-text"> — Lorem Ipsum</div>
              </div>
              <div className="reviews-rev-msg">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras
                vitae magna vulputate, hendrerit mi congue, congue nulla. Proin
                bibendum felis in risus tempus pulvinar. Morbi sed velit nec
                justo pharetra elementum sed quis lectus. Pellentesque rhoncus
                est et magna congue.
              </div>
              <div className="reviews-rev-dot">
                <i className="fa fa-circle"></i>
              </div>
            </div> */}
          </div>

          {/* <div className="reviews-row-2">
            <div className="reviews-row-1">
              <div className="reviews-col-1">
                <div className="reviews-rev-title">
                  <i className="fa fa-star"></i>
                  <i className="fa fa-star"></i>
                  <i className="fa fa-star"></i>
                  <i className="fa fa-star"></i>
                  <i className="fa fa-star"></i>
                  <div className="rev-title-text"> — Lorem Ipsum</div>
                </div>
                <div className="reviews-rev-msg">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras
                  vitae magna vulputate, hendrerit mi congue, congue nulla.
                  Proin bibendum felis in risus tempus pulvinar. Morbi sed velit
                  nec justo pharetra elementum sed quis lectus. Pellentesque
                  rhoncus est et magna congue.
                </div>
                <div className="reviews-rev-dot">
                  <i className="fa fa-circle"></i>
                </div>
              </div> */}
          {/* <div className="reviews-col-2">
                <div className="reviews-rev-title">
                  <i className="fa fa-star"></i>
                  <i className="fa fa-star"></i>
                  <i className="fa fa-star"></i>
                  <i className="fa fa-star"></i>
                  <i className="fa fa-star"></i>
                  <div className="reviews-rev-title-text"> — Lorem Ipsum</div>
                </div>
                <div className="reviews-rev-msg">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras
                  vitae magna vulputate, hendrerit mi congue, congue nulla.
                  Proin bibendum felis in risus tempus pulvinar. Morbi sed velit
                  nec justo pharetra elementum sed quis lectus. Pellentesque
                  rhoncus est et magna congue.
                </div>
                <div className="reviews-rev-dot">
                  <i className="fa fa-circle"></i>
                </div>
              </div> */}
          {/* </div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default Reviews;
